import React from 'react';
import { Table, Container, Row, Col, Card } from 'react-bootstrap';
import './PriceList.css';

const priceData = [
  { item: "Scaffolding Aps Ms ledger pipe 20feet", price: "75rs/per month/each peace" },
  { item: "Scaffolding Aps Ms ledger pipe 10 feet", price: "38rs" },
  { item: "Scaffolding Aps Ms 7feet vertical", price: "35rs" },
  { item: "Aps Ms 6 feet vertical", price: "32rs" },
  { item: "4 feet horizontal", price: "32rs" },
  { item: "2 feet horizontal", price: "25rs" },
  { item: "Joint pin", price: "12rs" },
  { item: "Couplar (fixed/swivel)", price: "12rs" },
  { item: "Adjustable base plate", price: "22rs" },
  { item: "U jack", price: "22rs" },
  { item: "Props 2mt", price: "60rs" },
  { item: "Props 3mt", price: "100rs" },
  { item: "Span", price: "90rs" },
  { item: "Centering sheet (3*2)", price: "30rs" },
];

const PriceListComponent = () => {
  return (
    <Container className="price-list-container">
      <h1 className="text-center mb-4">Product Tariff List</h1>
      <Row>
        <Col md={8} className="mx-auto">
          <Card>
            <Card.Body>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {priceData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.item}</td>
                      <td>{item.price}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Card className="mb-4">
            <Card.Body>
              <h4>Additional Information</h4>
              <p>Our prices are competitive and subject to change. We offer bulk discounts for large orders. Please contact us for more details.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PriceListComponent;
