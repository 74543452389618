import React, { useEffect, useState } from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import "./Navbar.css";
import images from "../../../assets/images/image";
import { NavLink } from "react-router-dom";

const NavBar = () => {
  const [navstate, setnavstate] = useState(false);
  const [NavInnerHTML, setNavInnerHTML] = useState(false);

  const handlenavclick = () => {
    setnavstate(!navstate);
  };

  useEffect(() => {
    const mql = window.matchMedia("(max-width: 991px)");
    const mobileView = mql.matches;
    setNavInnerHTML(mobileView);
  }, []);

  return (
    <Container fluid className="px-0">
      <Navbar
        id="navbar"
        className="newNav shadow-sm"
        expand="lg"
        expanded={navstate}
      >
        <Container>
          <Navbar.Brand href="/" className="logo-name">
            <img
              id="logo"
              src={images.logo}
              alt="Company Name"
              className="w-100"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            className="navbar-toggler"
            aria-controls="basic-navbar-nav"
            onClick={handlenavclick}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <NavLink
                exact
                to="/"
                className="nav-link nav-pad me-2"
                activeClassName="active"
                onClick={NavInnerHTML ? handlenavclick : null}
              >
                Home
              </NavLink>
              <NavLink
                to="/tariff"
                className="nav-link nav-pad me-2"
                activeClassName="active"
                onClick={NavInnerHTML ? handlenavclick : null}
              >
                Tariff
              </NavLink>
              <NavLink
                to="/gallery"
                className="nav-link nav-pad me-2"
                activeClassName="active"
                onClick={NavInnerHTML ? handlenavclick : null}
              >
                Gallery
              </NavLink>

              <NavLink
                to="/about"
                className="nav-link nav-pad me-2"
                activeClassName="active"
                onClick={NavInnerHTML ? handlenavclick : null}
              >
                About Us
              </NavLink>
              <NavLink
                to="/contact"
                className="nav-link nav-pad-last"
                activeClassName="active"
                onClick={NavInnerHTML ? handlenavclick : null}
              >
                Contact Us
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  );
};

export default NavBar;
