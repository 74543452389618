import {
    LocationIcon,
    PhoneIcon,
    MessageIcon,
    RightIcon,
  } from "../../../assets/icons/icons";
import images from "../../../assets/images/image";
export const services = [
    {
      image: images.steel,
      title: "Centring Materials",
      alt: "Centring Materials for scaffolding",
    },
    {
      image:
        "https://5.imimg.com/data5/PW/XS/MY-48195571/aps-unit-scaffolding-500x500.jpg",
      title: "APS Scaffolding System",
      alt: "APS Scaffolding System",
    },
    {
      image: images.valve,
      title: "Scaffolding Pipe & Fittings",
      alt: "Scaffolding Pipe and Fittings",
    },
    {
      image: images.concrete,
      title: "Scaffolding Accessories",
      alt: "Scaffolding Accessories",
    },
  ];

  export const scaffoldingService = [
    {
      id: 1,
      title: "Scaffolding Rental",
      icon: <LocationIcon />,
      para: "Wide range of scaffolding rentals for construction projects. Customized solutions to ensure safety and efficiency on-site.",
    },
    {
      id: 2,
      title: "Scaffolding Installation",
      icon: <PhoneIcon />,
      para: "Expert installation of stable and secure scaffolding platforms. Adherence to industry safety standards for various project heights.",
    },
    {
      id: 3,
      title: "Scaffolding Dismantling",
      icon: <MessageIcon />,
      para: "Professional and efficient dismantling of temporary scaffolding structures. Minimizes site disruption while prioritizing safety protocols.",
    },
    {
      id: 4,
      title: "Scaffolding Maintenance",
      icon: <RightIcon />,
      para: "Regular inspections and repairs to ensure scaffolding reliability. Comprehensive maintenance services for ongoing project safety.",
    },
  ];

  export const products = [
    {
      image: images.steel,
      title: "Centring Materials",
      dimensions: "10' x 5' x 2'",
    },
    {
      image: "https://5.imimg.com/data5/PW/XS/MY-48195571/aps-unit-scaffolding-500x500.jpg",
      title: "APS Scaffolding System",
      dimensions: "15' x 8' x 3'",
    },
    {
      title: 'Base Plate',
      image: images.baseplate,
      dimensions: "12 x 12 x 2",
    },
    {
      title: 'Scaffolding Wheel',
      image: images.casters,
      dimensions: "8 diameter x 2",
    },
    {
      title: 'Scaffolding U Jack',
      image: images.ujack,
      dimensions: "18x 6 x 6",
    },
    {
      title: 'Joint Pin',
      image: images.jointpin,
      dimensions: "6 x 1 diameter",
    },
    {
      title: 'Platform Jolly',
      image: images.jolly,
      dimensions: "6' x 3' x 4'",
    },
    {
      title: 'Scaffolding Couplar',
      image: images.scaffoldingcouplar,
      dimensions: "4 x 2 x 2",
    },
    {
      title: 'Adjustable Base Plate',
      image: images.baseplate2,
      dimensions: "12 x 12 x 4-8",
    },
    {
      title: 'Aps Horizontal and vertical pipe',
      image: images.apspipe,
      dimensions: "10 x 2 diameter",
    },
    {
      title: 'Scaffolding Boards',
      image: images.board,
      dimensions: "8' x 1' x 2",
    }
  ];