import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import HomeComponent from "./components/HomeComponent/HomComponent";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/shared/Layout/Layout";
import ProductPage from "./components/productpagecomponent/ProductPage";
import ContactForm from "./components/Contact/ContactForm";
import About from "./components/About/About";
import PriceListComponent from "./components/price-list/PriceList";

function App() {
  return (
    <Routes>
      <>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomeComponent />} />
          <Route path="/gallery" element={<ProductPage />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/about" element={<About />} />
          <Route path="/tariff" element={<PriceListComponent />} />
        </Route>
      </>
    </Routes>
  );
}

export default App;
