import React, { useState } from "react";
import { Row, Col, Card, Container, Modal } from "react-bootstrap";
import "./ProductPage.css";
import { galleryImages } from "../../utils/utils";
import { Helmet } from "react-helmet";

const ProductPage = () => {
  const [show, setShow] = useState({ open: false, modalData: null });
  const handleClose = () => setShow({ open: false });
  const handleShow = (image) => setShow({ open: true, modalData: image });

  return (
    <div className="gallery-page">
      <Helmet>
        <title>Image Gallery - Narayanasamy Industries</title>
        <meta
          name="description"
          content="Browse our gallery of images showcasing our products and services."
        />
        <meta
          name="keywords"
          content="gallery, images, products, services, Narayanasamy Industries"
        />
      </Helmet>
      <div className="hero-section">
        <iframe
          width="100%"
          height="400px%"
          src="https://www.youtube.com/embed/idGfUF-_sK8"
          title="Welcome Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="hero-video"
        ></iframe>
      </div>
      <Container className="py-5">
        <h2 className="text-center mb-5">Our Gallery</h2>
        <Row xs={1} sm={2} md={3} lg={4} className="g-4">
          {galleryImages.map((image, index) => (
            <Col key={index}>
              <Card
                className="gallery-card h-100"
                onClick={() => handleShow(image)}
              >
                <Card.Img
                  variant="top"
                  src={image}
                  alt={`Gallery image ${index + 1}`}
                />
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Modal show={show.open} onHide={handleClose} centered size="lg">
        <Modal.Body
          className="d-flex justify-content-center align-items-center position-relative"
          style={{ height: "80vh", overflow: "auto" }}
        >
          <img
            src={show.modalData}
            alt="Gallery"
            className="img-fluid"
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              objectFit: "contain",
            }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};


export default ProductPage;