import React, { useState, useRef } from "react";
import "./ContactForm.css";
import {
  Card,
  Col,
  Row,
  Button,
  Form,
  Container,
  Spinner,
} from "react-bootstrap";
import {
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaUser,
  FaPaperPlane,
} from "react-icons/fa";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

const ContactForm = () => {
  const [pending, setPending] = useState(false);
  const form = useRef();

  const sendEmail = (event) => {
    event.preventDefault();
    setPending(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then((result) => {
        toast.success("Email sent successfully!");
        console.log(result.text);
        form.current.reset();
        setPending(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setPending(false);
      });
  };

  return (
    <div className="contact-page">
      <Helmet>
        <title>Contact Us - Narayanasamy Industries</title>
        <meta
          name="description"
          content="Contact Narayanasamy Industries for inquiries about scaffolding rental services in Chennai. Reach out to us via phone or email, or visit our office at Vanagaram, Chennai."
        />
      </Helmet>

      <motion.section
        className="contact-hero"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <Container>
          <motion.h1
            className="text-center text-white mb-4"
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.2, type: "spring", stiffness: 120 }}
          >
            Get in Touch
          </motion.h1>
          <motion.p
            className="text-center text-white"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            We're here to answer any questions you may have about our
            scaffolding services
          </motion.p>
        </Container>
      </motion.section>

      <section className="contact-form-section py-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Card className="contact-card shadow">
                  <Card.Body>
                    <h2 className="text-center mb-4">Contact Us</h2>
                    <Form ref={form} onSubmit={sendEmail}>
                      <Form.Group className="mb-3">
                        <div className="input-group">
                          <span className="input-group-text">
                            <FaUser />
                          </span>
                          <Form.Control
                            name="user_name"
                            type="text"
                            className="field"
                            placeholder="Full Name"
                            required
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <div className="input-group">
                          <span className="input-group-text">
                            <FaPhone />
                          </span>
                          <Form.Control
                            name="mobileno"
                            type="tel"
                            className="field"
                            placeholder="Mobile Number"
                            required
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <div className="input-group">
                          <span className="input-group-text">
                            <FaEnvelope />
                          </span>
                          <Form.Control
                            name="user_email"
                            type="email"
                            className="field"
                            placeholder="Email Address"
                            required
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Control
                          as="textarea"
                          rows={4}
                          name="message"
                          className="field"
                          placeholder="Your Message"
                          required
                        />
                      </Form.Group>
                      <div className="d-grid">
                        <Button
                          type="submit"
                          className="submit-btn"
                          disabled={pending}
                        >
                          {!pending ? (
                            <>
                              Send Message <FaPaperPlane className="ms-2" />
                            </>
                          ) : (
                            <>
                              <Spinner
                                animation="border"
                                size="sm"
                                className="me-2"
                              />
                              Sending...
                            </>
                          )}
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="contact-info py-5 bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col md={4} className="mb-4 mb-md-0">
              <motion.div className="text-center" whileHover={{ scale: 1.05 }}>
                <FaMapMarkerAlt className="contact-icon" />
                <h3>Address</h3>
                <p>Vanagaram, Chennai</p>
              </motion.div>
            </Col>
            <Col md={4} className="mb-4 mb-md-0">
              <motion.div className="text-center" whileHover={{ scale: 1.05 }}>
                <FaPhone className="contact-icon" />
                <h3>Phone</h3>
                <p>
                  +91-7200989139
                  <br />
                  +91-9444861825
                </p>
              </motion.div>
            </Col>
            <Col md={4}>
              <motion.div className="text-center" whileHover={{ scale: 1.05 }}>
                <FaEnvelope className="contact-icon" />
                <h3>Email</h3>
                <p>narayanasamyindustries@gmail.com</p>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="map-section">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15545.555650343811!2d80.1538309!3d13.0745207!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5261bb18f6e9b9%3A0x79a7e5b5462cb176!2sNarayanasamy%20Industries!5e0!3m2!1sen!2sin!4v1680856685941!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Narayanasamy Industries Location"
        ></iframe>
      </section>
    </div>
  );
};

export default ContactForm;
