import React from "react";
import "./About.css";
import { Row, Col, Container } from "react-bootstrap";
import images from "../../assets/images/image";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { FaHistory, FaTools, FaUsers } from "react-icons/fa";

const About = () => {
  const clientImages = [
    images.clientdta,
    images.clientinnovative,
    images.clientjt,
    images.clientsm,
    images.clientsmc,
    images.smrclient,
    images.anitechclient,
    images.prakashclient,
  ];

  return (
    <div className="about-page">
      <Helmet>
        <title>About Narayanasamy Industries | Expert Scaffolding Services in Chennai</title>
        <meta
          name="description"
          content="Narayanasamy Industries is a leading scaffolding company in Chennai, providing safe and customizable scaffolding solutions for construction and maintenance projects. Learn about our history, expertise, and commitment to quality."
        />
        <meta
          name="keywords"
          content="Narayanasamy Industries, scaffolding Chennai, scaffolding contractors, scaffolding rentals, construction safety, Mogappair West"
        />
        <link rel="canonical" href="https://www.nsiscaffolding.com/about" />
      </Helmet>

      <motion.section 
        className="hero-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        style={{
          background: "linear-gradient(135deg, #1868fd 0%, #2a2a72 100%)",
          color: "white",
          padding: "4rem 0",
        }}
      >
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <motion.h1 
                className="hero-title text-white"
                initial={{ y: -50 }}
                animate={{ y: 0 }}
                transition={{ delay: 0.2, type: "spring", stiffness: 120 }}
              >
                About Narayanasamy Industries
              </motion.h1>
              <motion.p
                className="hero-subtitle"
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.8 }}
              >
                Your Trusted Partner in Scaffolding Solutions
              </motion.p>
            </Col>
          </Row>
        </Container>
      </motion.section>

      <section className="our-story py-5">
        <Container>
          <Row>
            <Col lg={4}>
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
              >
                <hr className="accent-line" />
                <h2 className="story-title">
                  <FaHistory className="icon-accent" /> Our Story
                </h2>
              </motion.div>
            </Col>
            <Col lg={8}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
              >
                <p>
                  Narayanasamy Industries, established in Mogappair West, Chennai, has been a cornerstone in the scaffolding industry for several years. Our journey began with a vision to provide safe, reliable, and customizable scaffolding solutions for construction and maintenance projects across Chennai.
                </p>
                <p>
                  Over the years, we've built a sterling reputation for our unwavering commitment to quality, safety, and efficiency. Our extensive range of scaffolding materials, including tube and coupler scaffolding, system scaffolding, and frame scaffolding, are crafted from premium materials and maintained to the highest industry standards.
                </p>
                <p>
                  What sets us apart is our team of seasoned professionals who bring years of expertise to every project. Whether it's a small repair job or a large-scale construction project, we pride ourselves on delivering tailored scaffolding solutions that meet the unique requirements of each client.
                </p>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="our-services py-5 bg-light">
        <Container>
          <motion.h2 
            className="section-title text-center mb-5"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <FaTools className="icon-accent" /> Our Services
          </motion.h2>
          <Row>
            {['Scaffolding Rental', 'Custom Solutions', 'Professional Setup', 'Safety Inspections'].map((service, index) => (
              <Col md={3} sm={6} key={index}>
                <motion.div 
                  className="service-card text-center"
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <h3>{service}</h3>
                </motion.div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <section className="client-section py-5">
        <Container>
          <motion.h2 
            className="section-title text-center mb-5"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <FaUsers className="icon-accent" /> Our Valuable Clients
          </motion.h2>
          <div className="logos-slider">
            <div className="overflow">
              <div className="logos-wrapper">
                {clientImages.map((image, index) => (
                  <motion.div 
                    className="logo-item" 
                    key={index}
                    whileHover={{ scale: 1.1 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <img src={image} alt={`Client logo ${index + 1}`} />
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default About;
