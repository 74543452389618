import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Row, Col, Container } from "react-bootstrap";
import "./HomComponent.css";
import images from "../../assets/images/image";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { products, scaffoldingService } from "./constants/home";
import { RightIcon } from "../../assets/icons/icons";
import { FaCogs, FaHardHat, FaTools, FaTruck } from "react-icons/fa";

const HomeComponent = () => {
  let location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <title>
          Scaffolding Rentals and Services in Chennai, Tamil Nadu | Your Trusted
          Construction Partner
        </title>
        <meta
          name="description"
          content="Expert scaffolding services for construction projects in Chennai, Tamil Nadu. We offer rentals, installation, maintenance, and dismantling of high-quality scaffolding systems. Ensure safety and efficiency in your construction work across India."
        />
        <meta
          name="keywords"
          content="scaffolding Chennai, construction scaffolding Tamil Nadu, scaffolding rental India, scaffolding installation Chennai, scaffolding maintenance Tamil Nadu, scaffolding dismantling, APS scaffolding Chennai, construction safety India"
        />
        <link rel="canonical" href="https://www.nsiscaffolding.com/" />
        <meta name="geo.region" content="IN-TN" />
        <meta name="geo.placename" content="Chennai" />
        <meta name="geo.position" content="13.0827;80.2707" />
        <meta name="ICBM" content="13.0827, 80.2707" />
      </Helmet>

      <motion.section
        className="hero"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        style={{
          background: "linear-gradient(135deg, #1868fd 0%, #2a2a72 100%)",
          color: "white",
          padding: "4rem 0",
        }}
      >
        <Container>
          <Row className="align-items-center">
            <Col lg={6} md={12} className="hero-content">
              <motion.h1
                className="hero-title text-white"
                initial={{ y: -50 }}
                animate={{ y: 0 }}
                transition={{ delay: 0.2, type: "spring", stiffness: 120 }}
              >
                Scaffolding Rentals and Hirers in Chennai
              </motion.h1>
              <motion.p
                className="hero-description"
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.8 }}
              >
                Elevate your construction projects with our top-tier scaffolding solutions in Chennai. We offer comprehensive services including <strong>scaffolding rentals</strong>, expert installation, and reliable maintenance for safe and efficient construction work.
              </motion.p>
              <motion.div
                className="cta"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <a href="/contact" className="btn ctc-btn">
                  Request a Free Quote
                </a>
              </motion.div>
            </Col>
            <Col lg={6} md={12}>
              <motion.div
                className="hero-image"
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.8 }}
              >
                <LazyLoadImage
                  src={images.herosection}
                  alt="Professional Scaffolding Services in Chennai"
                  className="img-fluid"
                  effect="blur"
                />
              </motion.div>
            </Col>
          </Row>
        </Container>
      </motion.section>

      <section className="services py-5">
        <Container>
          <motion.h2
            className="section-title text-center mb-5"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            Our Scaffolding Services
          </motion.h2>
          <Row className="justify-content-center">
            {scaffoldingService.map((service) => (
              <Col lg={3} md={6} sm={12} key={service.id} className="mb-4">
                <motion.div
                  className="service-card text-center p-4 shadow-sm rounded"
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: service.id * 0.1 }}
                  whileHover={{ scale: 1.05 }}
                >
                  <div className="service-icon mb-4">
                    {service.id === 1 && <FaTools className="fa-3x text-primary" />}
                    {service.id === 2 && <FaHardHat className="fa-3x text-primary" />}
                    {service.id === 3 && <FaTruck className="fa-3x text-primary" />}
                    {service.id === 4 && <FaCogs className="fa-3x text-primary" />}
                  </div>
                  <h3 className="service-title mb-3">{service.title}</h3>
                  <p className="service-description">
                    {service.para.split(".")[0]}.
                  </p>
                </motion.div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="products bg-light py-5">
        <Container>
          <motion.h2
            className="section-title text-center mb-5"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            Our Scaffolding Products
          </motion.h2>
          <Row className="justify-content-center">
            {products.map((product, index) => (
              <Col lg={4} md={6} sm={6} xs={12} key={index} className="mb-4">
                <motion.div
                  className="product-card shadow-sm rounded overflow-hidden"
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  whileHover={{ scale: 1.03 }}
                >
                  <div className="product-image-container">
                    <LazyLoadImage
                      src={product.image}
                      alt={product.title}
                      effect="blur"
                      className="product-image"
                    />
                  </div>
                  <div className="product-info p-3 bg-white">
                    <h3 className="product-title">{product.title}</h3>
                    <p className="product-dimensions">{product.dimensions}</p>
                      <div className="product-icon text-primary" onClick={() => window.location.href = '/tariff'}>
                        <RightIcon />
                    </div>
                  </div>
                </motion.div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <section
        className="cta-section"
        style={{
          background: "linear-gradient(135deg, #1868fd 0%, #2a2a72 100%)",
          color: "white",
          padding: "4rem 0",
        }}
      >
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <motion.h2
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
              >
                Ready to Elevate Your Construction Project?
              </motion.h2>
              <motion.p
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                Contact us today for a consultation on your scaffolding needs.
                Our experts are ready to provide tailored solutions for your
                construction project.
              </motion.p>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <a href="/contact" className="btn ctf-btn">
                  Request a Quote
                </a>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HomeComponent;
